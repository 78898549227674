import styles from "../styles/floating-button.module.css";
import Image from "next/image";
import { useEffect, useState } from "react";
import moment from "moment";
import Countdown, { zeroPad } from "react-countdown";

export default function FloatingButton() {
  const [isVisible, setIsVisible] = useState(false);
  const [nextEvent, setNextEvent] = useState(null);
  const [image, setImage] = useState(
    "https://res.cloudinary.com/aurumlab/image/upload/v1740710004/aurumlab/banner/FLASH-SALE-RAMADAN-start-in_m3vvdq.gif"
  );

  useEffect(() => {
    setIsVisible(true);
    const event = getNextEventTime();
    setNextEvent(event);
    if (event?.image) setImage(event.image);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const newEvent = getNextEventTime();
      if (JSON.stringify(newEvent) !== JSON.stringify(nextEvent)) {
        setNextEvent(newEvent);
        if (newEvent?.image) setImage(newEvent.image);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [nextEvent]);

  const renderer = ({ days, hours, minutes, seconds }) => (
    <span>
      <span className={styles.timer}>{zeroPad(days)}</span>:
      <span className={styles.timer}>{zeroPad(hours)}</span>:
      <span className={styles.timer}>{zeroPad(minutes)}</span>:
      <span className={styles.timer}>{zeroPad(seconds)}</span>
    </span>
  );

  const times = [
    { name: "Ngabuburit", start: "13:00", end: "16:00" },
    { name: "Taraweh", start: "20:00", end: "23:59" },
  ];

  const eventDays = [1, 3, 5];

  const getNextEventTime = () => {
    const now = moment();
    const currentDay = now.day();

    for (const { start, end } of times) {
      let startTime = moment().set({
        hour: start.split(":")[0],
        minute: start.split(":")[1],
        second: 0,
      });

      let endTime = moment().set({
        hour: end.split(":")[0],
        minute: end.split(":")[1],
        second: 0,
      });

      if (eventDays.includes(currentDay) && now.isBefore(startTime)) {
        return {
          label: "Start in",
          targetTime: startTime,
          image:
            "https://res.cloudinary.com/aurumlab/image/upload/v1740710004/aurumlab/banner/FLASH-SALE-RAMADAN-start-in_m3vvdq.gif",
        };
      }

      if (eventDays.includes(currentDay) && now.isBetween(startTime, endTime)) {
        return {
          label: "Ends in",
          targetTime: endTime,
          image:
            "https://res.cloudinary.com/aurumlab/image/upload/v1740731865/aurumlab/banner/FLASH-SALE-RAMADAN-ends-in_ndqrhr.gif",
        };
      }
    }

    const nextEventDay = eventDays
      .map((d) => (d - currentDay + 7) % 7)
      .filter((d) => d > 0)
      .sort((a, b) => a - b)[0];

    if (nextEventDay !== undefined) {
      const nextStartTime = moment()
        .add(nextEventDay, "days")
        .set({
          hour: times[0].start.split(":")[0],
          minute: times[0].start.split(":")[1],
          second: 0,
        });
      return { label: "Start in", targetTime: nextStartTime };
    }

    return null;
  };

  return (
    <>
      {isVisible && nextEvent && (
        <div className={styles.floatingButton}>
          <Image
            key={image}
            src={image}
            alt="floating button"
            width={200}
            height={200}
            onClick={() => {
              window.location.href = "/flash-sale";
            }}
            className={styles.image}
          />
          <div className={styles.countdown}>
            <Countdown
              key={nextEvent?.targetTime?.toISOString()}
              date={nextEvent.targetTime.toDate()}
              renderer={renderer}
              onComplete={() => {
                const newEvent = getNextEventTime();
                setNextEvent(newEvent);
                if (newEvent?.image) setImage(newEvent.image);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
